export const mapText = {
    en: {
      saLocationTitle: 'San Antonio Location',
      nyLocationTitle: 'Manhattan Location',
    },
    es: {
      saLocationTitle: 'Ubicación de San Antonio',
      nyLocationTitle: 'Ubicación de Manhattan',
    },
};
  